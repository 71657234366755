import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Home.vue'),
      meta: { title: '首页', keywords: '', description: '' }
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('./views/About.vue'),
      meta: { title: '公司介绍', keywords: '', description: '' }
    },
    {
      path: '/story',
      name: 'story',
      component: () => import('./views/Story.vue'),
      meta: { title: '品牌故事', keywords: '', description: '' }
    },
    {
      path: '/history',
      name: 'history',
      component: () => import('./views/History.vue'),
      meta: { title: '发展历程', keywords: '', description: '' }
    },
    {
      path: '/honor',
      name: 'honor',
      component: () => import('./views/Honor.vue'),
      meta: { title: '企业荣誉', keywords: '', description: '' }
    },
    {
      path: '/assistant',
      name: 'assistant',
      component: () => import('./views/Assistant.vue'),
      meta: { title: '门窗管家', keywords: '', description: '' }
    },
    {
      path: '/partner',
      name: 'partner',
      component: () => import('./views/Partner.vue'),
      meta: { title: '合作伙伴', keywords: '', description: '' }
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('./views/Contact.vue'),
      meta: { title: '联系方式', keywords: '', description: '' }
    },
    {
      path: '/hr',
      name: 'hr',
      component: () => import('./views/Hr.vue'),
      meta: { title: '企业招聘', keywords: '', description: '' }
    },
    {
      path: '/unify',
      name: 'unify',
      component: () => import('./views/Unify.vue'),
      meta: { title: '洞口一体化解决方案', keywords: '', description: '' }
    },
    {
      path: '/quick_change',
      name: 'quickChange',
      component: () => import('./views/QuickChange.vue'),
      meta: { title: '快速换窗服务', keywords: '', description: '' }
    },
    {
      path: '/join',
      name: 'join',
      component: () => import('./views/Join.vue'),
      meta: { title: '招商加盟', keywords: '', description: '' }
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('./views/News.vue'),
      meta: { title: '新闻', keywords: '', description: '' }
    },
    {
      path: '/new/:id(\\d+)',
      name: 'newsDetail',
      component: () => import('./views/New.vue'),
      meta: { title: '新闻详情', keywords: '', description: '' }
    },
    {
      path: '/cases/:pid(\\d+)',
      name: 'cases',
      component: () => import('./views/Cases.vue'),
      meta: { title: '案例', keywords: '', description: '' }
    },
    {
      path: '/case/:id(\\d+)',
      name: 'caseDetail',
      component: () => import('./views/CaseDetail.vue'),
      meta: { title: '案例详情', keywords: '', description: '' }
    },
    {
      path: '/products/:p1(\\d+)/:p2(\\d+)?',
      name: 'products',
      component: () => import('./views/Products.vue'),
      meta: { title: '产品', keywords: '', description: '' }
    },
    {
      path: '/product/:id(\\d+)',
      name: 'productDetail',
      component: () => import('./views/ProductDetail.vue'),
      meta: { title: '产品详情', keywords: '', description: '' }
    },
    {
      path: '/store',
      name: 'store',
      component: () => import('./views/Store.vue'),
      meta: { title: '门店信息', keywords: '', description: '' }
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('./views/Privacy.vue'),
      meta: { title: '隐私协议', keywords: '', description: '', useGlobalLayout: false }
    }
  ]
})
